import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CategoryCarousel from "../components/category-carousel";
import LoadScreen from "../components/Load-screen";
import SearchBar from "../components/SearchBar";
import PlayerContext from "../context/Player-context";

export default function Series() {
  const { selectedChannel, setSelectedSeries } = useContext(PlayerContext);

  const [groups, setGroups] = useState<string[]>([]);

  const [defaultGroup, setDefaultGroup] = useState<string[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>();

  const [isLoaded, setIsLoaded] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const getSeriesTitles = async () => {
      // Get subcategory using selected category
      // Navigate back if there are errors or no categories

      try {
        const { data } = await axios.get<string[]>(
          `${apiUrl}/series/${selectedChannel}`
        );

        if (!data.length) navigate(-1);

        setGroups(data);

        setDefaultGroup(data);

        setIsLoaded(true);
      } catch (error) {
        navigate(-1);
      }
    };

    getSeriesTitles();
  }, [apiUrl, navigate, selectedChannel]);

  const handleGroupClick = (group: string) => {
    // Set selected subcategory and navigate to the media player
    setSelectedSeries(group);
    navigate("/current");
  };

  const handleSearch = () => {
    if (!searchTerm) return;

    const lowGroup = groups.map((item) => item.toLowerCase());

    const filteredGroup = lowGroup.filter((item) =>
      item.includes(searchTerm.toLowerCase())
    );

    setGroups(filteredGroup);
  };

  const clearSearch = () => {
    setGroups(defaultGroup);
  };

  return (
    <React.Fragment>
      {!isLoaded && <LoadScreen />}
      {isLoaded && (
        <div className="h-screen bg-gray-800 flex flex-col">
          <div className="h-[30%]">
            {/* Nav bar */}
            <div className="flex justify-between p-2">
              <span
                className="cursor-pointer font-bold uppercase text-blue-700 font-mono px-4 py-2 bg-slate-300 rounded-md"
                onClick={() => navigate(-1)}
              >
                Back to Categories
              </span>
              <span
                className="cursor-pointer font-bold uppercase text-blue-700 font-mono px-4 py-2 bg-slate-300 rounded-md"
                onClick={() => navigate(0)}
              >
                Refresh
              </span>
            </div>
            {/* Search bar */}
            <SearchBar
              onChangeTerm={(e) => setSearchTerm(e.currentTarget.value)}
              onSearch={handleSearch}
              onCancel={clearSearch}
              placeholder="Search Subcategories"
            />
          </div>

          {/* Groups */}
          <div className="h-[70%] flex justify-center items-center">
            {groups && (
              <CategoryCarousel category={groups} action={handleGroupClick} />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

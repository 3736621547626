import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";

import PlayerContext from "./context/Player-context";
import { Current, Home, Player } from "./pages";
import SubCategory from "./pages/SubCategory";
import Series from "./pages/Series";

function App() {
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedGroup, setSelectedGroup] = useState<string>();
  const [selectedChannel, setSelectedChannel] = useState<string>();
  const [selectedSeries, setSelectedSeries] = useState<string>();

  return (
    <div className="">
      <PlayerContext.Provider
        value={{
          selectedGroup,
          setSelectedGroup,
          selectedCategory,
          setSelectedCategory,
          selectedChannel,
          setSelectedChannel,
          selectedSeries,
          setSelectedSeries,
        }}
      >
        {/* Routes */}
        <Routes>
          <Route path="current" element={<Current />} />
          <Route path="series" element={<Series />} />
          <Route path="subcategory" element={<SubCategory />} />
          <Route path="play" element={<Player />} />
          <Route path="" element={<Home />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </PlayerContext.Provider>
    </div>
  );
}

export default App;

import React from "react";
import parser from "iptv-playlist-parser";

interface VideoPlayerProps {
  activeMedia: parser.PlaylistItem;
}

export default function VideoPlayer({ activeMedia }: VideoPlayerProps) {
 
  return (
     <React.Fragment>
        <object data={`https://shop.emarkets24.com/streamfolder/receiver.html?receiver=${activeMedia.url}&&name-${activeMedia.name}`} type="text/html"></object>
     
      </React.Fragment>
   
   
  );
}

import React from "react";

interface SearchProp {
  onChangeTerm: (e: any) => any;
  onSearch: () => any;
  onCancel: () => any;
  placeholder: string;
}

export default function SearchBar({
  onCancel,
  onChangeTerm,
  onSearch,
  placeholder,
}: SearchProp) {
  return (
    <div className="flex items-center justify-center">
      <div className="mx-2 w-full">
        <input
          type="text"
          name="category"
          id="category"
          className="px-2 py-1 border outline-none rounded w-full"
          placeholder={placeholder}
          onChange={onChangeTerm}
        />
      </div>

      <button
        className="mx-1 px-2 py-1 bg-green-700 text-white rounded"
        onClick={onSearch}
      >
        Search
      </button>

      <button
        className="mx-1 px-2 py-1 bg-red-700 text-white rounded"
        onClick={onCancel}
      >
        Clear
      </button>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import PlayerContext from "../context/Player-context";
import { useNavigate } from "react-router-dom";
import parser from "iptv-playlist-parser";
import axios from "axios";
import LoadScreen from "../components/Load-screen";
import VideoPlayer from "../components/VideoPlayer";

export default function Current() {
  // Shows current channel

  const { selectedGroup, setSelectedGroup, selectedSeries, setSelectedSeries } =
    useContext(PlayerContext);
  const [selectedPlaylist, setSelectedPlaylist] = useState<
    parser.PlaylistItem[]
  >([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const navigate = useNavigate();

  const [activeMedia, setActiveMedia] = useState<parser.PlaylistItem>();

  // const vidRef = React.createRef<HTMLVideoElement>();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const getMediaPlaylist = async () => {
      // Get media list using the selected subcategory
      // Navigate back if there is error or empty list

      try {
        if (selectedSeries) {
          const { data } = await axios.get<parser.PlaylistItem[]>(
            `${apiUrl}/current-series/${selectedSeries}`
          );

          if (!data.length) navigate(-1);

          setSelectedPlaylist(data);

          setIsLoaded(true);
        } else {
          const { data } = await axios.get<parser.PlaylistItem[]>(
            `${apiUrl}/media/${selectedGroup}`
          );

          if (!data.length) navigate(-1);

          setSelectedPlaylist(data);

          setIsLoaded(true);
        }
      } catch (error) {
        navigate(-1);
      }
    };

    getMediaPlaylist();

    return () => {
      setSelectedSeries(undefined);
      setSelectedGroup(undefined);
    };
  }, [
    selectedGroup,
    apiUrl,
    navigate,
    selectedSeries,
    setSelectedGroup,
    setSelectedSeries,
  ]);

  useEffect(() => {
    // Set first media as active
    selectedPlaylist &&
      selectedPlaylist.length &&
      setActiveMedia(selectedPlaylist[0]);
  }, [selectedPlaylist]);

  return (
    <React.Fragment>
      {!isLoaded && <LoadScreen />}
      {isLoaded && (
        <div className="bg-gray-800">
          <div className="w-full sm:flex">
            <div className="sticky top-0 bg-gray-800">
              {/* Nav bar */}
              <div className="flex justify-between p-2">
                <span
                  className="cursor-pointer font-bold uppercase text-blue-700 font-mono px-4 py-2 bg-slate-300 rounded-md"
                  onClick={() => navigate(-1)}
                >
                  Back to Subcategory
                </span>
                <span
                  className="cursor-pointer font-bold uppercase text-blue-700 font-mono px-4 py-2 bg-slate-300 rounded-md"
                  onClick={() => navigate(0)}
                >
                  Refresh
                </span>
              </div>

              {/* Video Player and title */}
              <div className="w-full flex flex-col font-bold items-center justify-center text-gray-200">
                {activeMedia && (
                  <span className="block text-xl my-2">{activeMedia.name}</span>
                )}
                {/*video player */}
                {activeMedia && <VideoPlayer activeMedia={activeMedia} />}
              </div>
            </div>

            {/* List of channels */}
            <div className="flex flex-col sm:max-h-screen sm:overflow-y-scroll">
              {selectedPlaylist &&
                selectedPlaylist.length &&
                selectedPlaylist.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex items-center cursor-pointer h-[100px] shadow-md mb-2"
                    onClick={() => setActiveMedia(item)}
                  >
                    <div className="w-1/4 px-2">
                      <img
                        className="w-full h-[100px]"
                        src={item.tvg.logo}
                        alt={item.tvg.name}
                      />
                    </div>
                    <div className="w-3/4 px-2">
                      <span className="text-gray-200">{item.name} </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

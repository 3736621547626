import React from "react";

import playGif from "../asset/icons8-play-button-circled.gif";

import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();

  const readPlaylist = () => {
    navigate("/play");
  };

  return (
    <React.Fragment>
      <div className="h-screen flex items-center justify-center bg-gray-800">
        <div className="w-full flex flex-col justify-center items-center mb-3">
          <img
            src={playGif}
            alt="play"
            onClick={() => readPlaylist()}
            className="w-[100px] h-[100px] cursor-pointer bg-grey-800"
          />
          <span className="text-white-100 font-bold text-3xl block text-center my-3">
            PLAY
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}

import React from "react";

interface PlayerAPI {
  selectedCategory: string | undefined;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string | undefined>>;

  selectedGroup: string | undefined;
  setSelectedGroup: React.Dispatch<React.SetStateAction<string | undefined>>;

  selectedChannel: string | undefined;
  setSelectedChannel: React.Dispatch<React.SetStateAction<string | undefined>>;

  selectedSeries: string | undefined;
  setSelectedSeries: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const PlayerContext = React.createContext<PlayerAPI>({
  selectedCategory: undefined,
  setSelectedCategory: () => undefined,

  selectedGroup: undefined,
  setSelectedGroup: () => undefined,

  selectedChannel: undefined,
  setSelectedChannel: () => undefined,

  selectedSeries: undefined,
  setSelectedSeries: () => undefined,
});

PlayerContext.displayName = "Player Context";

export default PlayerContext;

import React from "react";
import loadingGif from "../asset/loading.gif";

export default function LoadScreen() {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col item-center">
        <span className="font-bold text-xl uppercase">Loading</span>
        <img src={loadingGif} alt="" className="w-[100px] h-[100px]" />
      </div>
    </div>
  );
}

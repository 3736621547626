import React from "react";

interface CarouselProps {
  category: string[];
  action: (item: string) => any;
}

export default function CategoryCarousel({ category, action }: CarouselProps) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 overflow-y-scroll h-full w-full">
      {category.length &&
        category.map((group, index) => (
          <div
            key={index}
            className="flex items-center justify-center mx-2 my-2 p-2 text-gray-500 bg-gray-200 cursor-pointer rounded uppercase font-bold text-lg tracking-wider text-center"
            onClick={() => action(group)}
          >
            {group}
          </div>
        ))}
    </div>
  );
}
